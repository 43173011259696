import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import ProductHeader from "gatsby-theme-rothenberger/src/components/blocks/ProductHeader"
import ProductCard from "gatsby-theme-rothenberger/src/components/molecules/ProductCard"
import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import { graphql, Link } from "gatsby"
import striptags from "striptags"

const CategoryTemplate = ({ data }) => {
  const collection = data.shopifyCollection
  const allCollections = data.allCategories

  const filteredCollections = allCollections?.nodes.filter((collection) => {
    return collection.products.some((product) => product.totalInventory > 0)
  })

  const breadcrumbs = [
    {
      link: "/de-de/produkte",
      link_text: "Produkte",
    },
  ]

  return (
    <>
      <Layout language="de">
        <SEO
          title={collection.title}
          description={striptags(collection.descriptionHtml).replace(
            /\n{1,}/g,
            " "
          )}
          og_image={collection.image.src}
          language="de"
        />
        <ProductHeader
          block={{ headline: collection.title, _uid: collection.shopifyId }}
          breadcrumbs={breadcrumbs}
        />
        <section className="py-block">
          <div className=" block-w-container">
            <div className="flex flex-row flex-wrap -mx-grid">
              <div className="w-full mb-8 md:w-1/3 lg:w-1/4 px-grid">
                <div className="py-4 border border-gray-100">
                  {filteredCollections.map((group, index) => {
                    return (
                      <div
                        key={`${collection.handle}-${index}`}
                        className="block"
                      >
                        <Link
                          className={`${
                            collection.handle === group.handle && "font-bold"
                          } inline-block py-2 text-sm px-grid hover:text-primary`}
                          to={`/de-de/produkte/${group.handle}`}
                        >
                          {group.title}
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="w-full md:w-2/3 lg:w-3/4 px-grid">
                <div className="flex flex-row flex-wrap -mx-grid">
                  {collection.products.map(
                    (product) =>
                      product.totalInventory > 0 && (
                        <div
                          className="flex w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
                          key={product.shopifyId}
                        >
                          <ProductCard
                            item={{
                              headline: product.title,
                              text: collection.title,
                              image: {
                                src: product.featuredImage
                                  ? product.featuredImage.src
                                  : process.env.GATSBY_IMG_PLACEHOLDER,
                                alt: product.featuredImage.altText
                                  ? product.featuredImage.altText
                                  : product.title,
                              },
                              price: {
                                price:
                                  product.variants[0].presentmentPrices[0]
                                    .price,
                                compareAtPrice:
                                  product.variants[0].presentmentPrices[0]
                                    .compareAtPrice,
                              },
                              link: `/de-de/produkte/${product.handle}`,
                            }}
                          />
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {entry?.properties?.description?.length > 0 && (
          <section>
            <div className="pb-block block-w-container">
              <div className="flex flex-row flex-wrap -mx-grid">
                <div className="w-full lg:w-1/4 px-grid "></div>
                <div className="w-full lg:w-2/4 px-grid ">
                  <Richtext text={entry?.properties?.description} />
                </div>
              </div>
            </div>
          </section>
        )} */}
      </Layout>
    </>
  )
}

export const query = graphql`
  query CollectiontQuery($shopifyId: String) {
    shopifyCollection(shopifyId: { eq: $shopifyId }) {
      title
      descriptionHtml
      handle
      image {
        src
      }
      products {
        totalInventory
        title
        handle
        featuredImage {
          altText
          src
        }
        variants {
          sku
          presentmentPrices {
            compareAtPrice {
              amount
              currencyCode
            }
            price {
              amount
              currencyCode
            }
          }
        }
      }
    }
    allCategories: allShopifyCollection(
      sort: { fields: metafields___value, order: ASC }
    ) {
      nodes {
        title
        handle
        shopifyId
        products {
          totalInventory
        }
      }
    }
  }
`

export default CategoryTemplate
